import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//Sortable Kendo
//import { SortableModule } from '@progress/kendo-angular-sortable';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
  ContactosService,
  TransportesService,
  EnlacesService,
  RecorridosService,
  DepartamentosService,
  ConfiguracionService,
} from '@app/_services';
import { ContactosenteService } from "./_services/contactosente.service";
import { OrganizacionesService } from "@app/_services/organizaciones.service";
import { IdiomasService } from "@app/_services/idiomas.service";
import { AgentesService } from "@app/_services/agentes.service";
import { ColaboradoresService } from "@app/_services/colaboradores.service";
import { DepartamentosEnteService } from "@app/_services/departamentosente.service";

//Angular
import '@progress/kendo-angular-intl/locales/es/all';
import {
  DropDownsModule,
  ComboBoxModule,
} from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from "@progress/kendo-angular-layout";
//Paginas
import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { ContactosComponent } from './contactos/contactos.component';
import { ContactosDetalleComponent } from './contactos/contactos-detalle/contactos-detalle.component';
import { TransportesComponent } from './transportes/transportes.component';
import { TransportesDetalleComponent } from './transportes/transportes-detalle/transportes-detalle.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { EnlacesDetalleComponent } from './enlaces/enlaces-detalle/enlaces-detalle.component';
import { RecorridosComponent } from './recorridos/recorridos.component';
import { RecorridosDetalleComponent } from './recorridos/recorridos-detalle/recorridos-detalle.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { DepartamentosDetalleComponent } from './departamentos/departamentos-detalle/departamentos-detalle.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { PruebaComponent } from './prueba/prueba.component';
import { PatrocinadoresComponent } from './patrocinadores/patrocinadores.component';
import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrganizacionesDetalleComponent } from './organizaciones/organizaciones-detalle/organizaciones-detalle.component';
import { ConfiguracionInicialComponent } from './configuracion-inicial/configuracion-inicial.component';
import { AgentesComponent } from './agentes/agentes.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { AgentesdetalleComponent } from "./agentes/agentesdetalle/agentesdetalle.component";
import { ColaboradoresdetalleComponent } from "./colaboradores/colaboradoresdetalle/colaboradoresdetalle.component";
import { AgentesTiposComponent } from './agentes-tipos/agentes-tipos.component';
import { AgentesTiposDetallesComponent } from './agentes-tipos/agentes-tipos-detalles/agentes-tipos-detalles.component';
import { PopupModule } from '@progress/kendo-angular-popup';;
import { DepartamentosEnteComponent } from './departamentos-ente/departamentos-ente.component';
import { DepartamentosEnteDetallesComponent } from './departamentos-ente/departamentos-ente-detalles/departamentos-ente-detalles.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ContactosEnteComponent } from './contactos-ente/contactos-ente.component';
import { ContactosEnteDetallesComponent } from './contactos-ente/contactos-ente-detalles/contactos-ente-detalles.component';
@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    //SortableModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    LabelModule,
    NgbModule,
    UploadsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    IconsModule,
    PopupModule,
    DialogsModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    ContactosComponent,
    ContactosDetalleComponent,
    TransportesComponent,
    TransportesDetalleComponent,
    DetailsComponent,
    UpdateComponent,
    EnlacesComponent,
    EnlacesDetalleComponent,
    RecorridosComponent,
    RecorridosDetalleComponent,
    DepartamentosComponent,
    DepartamentosDetalleComponent,
    ConfiguracionComponent,
    PruebaComponent,
    PatrocinadoresComponent,
    OrganizacionesComponent,
    OrganizacionesDetalleComponent,
    ConfiguracionInicialComponent ,
    AgentesComponent ,
    AgentesdetalleComponent,
    ColaboradoresComponent,
    ColaboradoresdetalleComponent,
    AgentesTiposComponent,
    AgentesTiposDetallesComponent,
  DepartamentosEnteComponent,
  DepartamentosEnteDetallesComponent,
  ContactosEnteComponent,
  ContactosEnteDetallesComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UsuariosService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService,
    UsuariosTiposService,
    ContactosService,
    TransportesService,
    EnlacesService,
    RecorridosService,
    DepartamentosService,
    DepartamentosEnteService,
    ConfiguracionService,
    OrganizacionesService,
    IdiomasService,
    AgentesService,
    ColaboradoresService,
    ContactosenteService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
