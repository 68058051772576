import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ContactosService, MenuService } from '@app/_services';
import { ContactosenteService } from '@app/_services/contactosente.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-contactos-ente-detalles',
  templateUrl: './contactos-ente-detalles.component.html'
})
export class ContactosEnteDetallesComponent implements OnInit {

  form: FormGroup;
  codigo: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private contactosService: ContactosenteService,
    private alertService: AlertService,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('contactos.contactos');
    
    this.codigo = this.route.snapshot.params['id'];

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
        codigo: this.route.snapshot.params['id'],
        email: new FormControl('', [Validators.email]),
        telefono: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')])             
    });

    if (!this.isAddMode) {
        this.contactosService.GetById(this.codigo)
            .pipe()
            .subscribe((result) => {
              this.form = this.formBuilder.group({
                codigo: this.route.snapshot.params['id'],
                nombre1: new FormControl(result.data[0].contacto, [Validators.required]),
                email: new FormControl(result.data[0].email, [Validators.email]),
                telefono: new FormControl(result.data[0].phone, [Validators.required, Validators.pattern('^[0-9]*$')])             
              });
            });
    }
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        // TODO This as a workaround.
        this.menuService.titulo = this.translateService.instant('contactos.contactos');
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (this.isAddMode) { 
        this.crearContacto();
    } else {
        this.updateContacto();
    }
  }

  private crearContacto() {
    this.contactosService.insert(this.form.value)
        .subscribe((result) => { 
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../'], { relativeTo: this.route });
            } else {
              this.alertService.error(this.translateService.instant('contactos.errorcrear'));
              this.loading = false;
            }
        });
  }

  private updateContacto() {
    this.contactosService.update(this.form.value)
        .pipe(first())
        .subscribe((result) => {
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../../'], { relativeTo: this.route });
            } else {
              this.alertService.error(this.translateService.instant('contactos.erroreditar'));
              this.loading = false;
            }
        });
  }

  
    
}
