import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartamentoUsuario } from '@app/_models/departamento-usuario';
import { AlertService, ContactosService, MenuService, UsuariosService } from '@app/_services';
import { AgentesService } from '@app/_services/agentes.service';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-departamentos-ente-detalles',
  templateUrl: './departamentos-ente-detalles.component.html',
})
export class DepartamentosEnteDetallesComponent implements OnInit {

  form: FormGroup;
  codigo: number;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  idOrg:string;
  public usuariosData: DepartamentoUsuario[] = [];
  public camposValoresIsFromDep:boolean[];
  public listaUsuariosDep:DepartamentoUsuario[];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private departamentosEnteService: DepartamentosEnteService,
    private alertService: AlertService,
    private menuService: MenuService
  ) {  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('agentes.departamentos');
    
    console.log('LLega id');
    this.idOrg =this.route.snapshot.paramMap.get('idorg');
    console.log('Id es: '+this.idOrg);
    this.route.params.subscribe((params) => {
      console.log('Ha cambiado:');
      let idOrg = params.get('idorg');
  });

    this.codigo = this.route.snapshot.params['idDep'];
    //this.idOrg = this.route.snapshot.params['id'];

    this.isAddMode = !this.codigo;

    this.form = this.formBuilder.group({
        codigo: this.route.snapshot.params['idDep'],
        idOrg: this.idOrg,
        nombre: new FormControl('', [Validators.required]),
        descripcion: new FormControl('', [Validators.required]),
        activo: new FormControl(true),
             
    });

    if (!this.isAddMode) {
      this.departamentosEnteService.GetDepById(this.codigo)
          .pipe()
          .subscribe((result) => {
            this.form = this.formBuilder.group({
              codigo: this.route.snapshot.params['idDep'],
              idOrg: this.route.snapshot.params['id'],
              nombre: new FormControl(result.data[0].nombre, [Validators.required]),
              descripcion: new FormControl(result.data[0].descripcion, [Validators.required]),
              activo :new FormControl(result.data[0].activo),
            });
          });
  }

  //Load users-from-deps
  this.departamentosEnteService.getUsersFromDep(this.codigo)
  .pipe()
  .subscribe((result) => {
    console.log('Llegan usuarios');
    console.log(result);
    this.usuariosData = result.data;
    console.log(this.usuariosData.length);
    result.data.forEach(element => {
      this.camposValoresIsFromDep[element.id]=element.isFromDep;
    });
  });

  this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('contactos.contactos');
  });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (this.isAddMode) { 
        this.crearDepartametno();
    } else {
        this.updateDepartamento();
    }
  }

  private crearDepartametno() {
    console.log('Creando Departamento');
    this.departamentosEnteService.insertDepartamento(this.form.value)
        .subscribe((result) => { 
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../'], { relativeTo: this.route });
            } else {
              this.alertService.error(this.translateService.instant('contactos.errorcrear'));
              this.loading = false;
            }
        });
  }

  private updateDepartamento() {
    console.log('Actualizando Departamento');
    this.departamentosEnteService.updateDepartamento(this.form.value)
        .pipe(first())
        .subscribe((result) => {
            if(!result.error) {
              this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../../'], { relativeTo: this.route });

              //update users asigned to departments
              this.usuariosData.forEach(element => {

                var usr = new DepartamentoUsuario();

                  usr.isFromDep=this.camposValoresIsFromDep[element.id];
                  usr.id=element.id;
                  usr.idUser = element.idUser;
                  this.listaUsuariosDep.push(usr);

              });
              this.departamentosEnteService.updateUsersFromDepartment(this.listaUsuariosDep).pipe(first())
              .subscribe((result) => {});

            } else {
              this.alertService.error(this.translateService.instant('contactos.erroreditar'));
              this.loading = false;
            }
        });
  }


  onChange(e) {
    console.log('Entramos');
    e.selectedRows.map(item => {
      console.log('selected');
      console.log(item);
      item.dataItem.isFromDep = true;
      return item;
    });

    e.deselectedRows.map(item => {
      console.log('deselected');
      console.log(item);
      item.dataItem.isFromDep = false;
      return item;
    })
  }

}
