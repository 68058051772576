<div *ngIf="dataLoaded; else dataLoading">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-4">
                        <!--GESTION AGENTE-->
                        <div *ngIf="!isAddMode" class="card">
                          <div class="card-header">
                            <h3>{{ "agentes.gestionar" | translate }}</h3>
                          </div>
                          <div class="card-body">
                            <button
                            kendoButton
                            (click)="openGestionAdmins()"
                            class="btn mr-1 btn-primary btn-sm mr-1"
                          >
                            {{ "agentes.gestionadmins" | translate }}
                          </button>

                          </div>
                        </div>
                        <!--END GESTION AGENTE-->
        <!--DATOS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.datos" | translate }}</h3>
          </div>
          <div class="card-body">

            <kendo-label text="{{ 'organizaciones.nombre' | translate }}">
              <kendo-textbox formControlName="nombreOrg"
                             [ngClass]="{ 'is-invalid': submitted && f.nombreOrg.errors }">
              </kendo-textbox>
            </kendo-label>

            <span style="color: red;" *ngIf="this.form.get('nombreOrg').errors?.required">
              {{ 'organizaciones.valNombreOrg' | translate }}
            </span>

            <kendo-label text="{{ 'organizaciones.avatar' | translate }}">
            </kendo-label>

            <div class="row">

              <div class="col-10">
                <kendo-fileselect formControlName="logo"
                                  class="form-control"
                                  [restrictions]="restrictions"
                                  [multiple]="false"
                                  (select)="selectEventHandler($event)"
                                  (remove)="removeEventHandler($event)">
                  <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                                         dropFilesHere="{{ 'organizaciones.dropFoto' | translate }}"
                                         invalidFileExtension="{{
                      'organizaciones.extFotoIn' | translate
                    }}"
                                         invalidMaxFileSize="{{
                      'organizaciones.invalidMax' | translate
                    }}"
                                         remove="{{ 'organizaciones.remFoto' | translate }}"
                                         select="{{ 'organizaciones.slFoto' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
              </div>

              <div class="col-lg-12 col-xl-2">
                <div *ngIf="!avatarBorrado && avatarPreview">
                  <button kendoButton
                          (click)="onClickEliminarImagen(content)"
                          class="btn-sm btn-danger mr-1"
                          [disabled]="isNotInsA">
                    {{ "botones.eliminar" | translate }}
                  </button>
                </div>
              </div>
            </div>

            <ng-template #notImConf>
              <span><i>{{ "organizaciones.noimagen" | translate }}</i></span>
            </ng-template>

            <div *ngIf="avatarPreview; else notImConf">
              <img [src]="avatarPreview" style="max-height: 113px" />
            </div>

            <kendo-label text="{{ 'organizaciones.cabecera' | translate }}">
            </kendo-label>

            <div class="row">

              <div class="col-10">

                <kendo-fileselect formControlName="cabecera"
                                  class="form-control"
                                  [restrictions]="restrictions"
                                  [multiple]="false"
                                  (select)="selectEventHandlerCabecera($event)"
                                  (remove)="removeEventHandlerCabecera($event)">
                  <kendo-upload-messages cancel="{{ 'botones.cancelar' | translate }}"
                                         dropFilesHere="{{ 'organizaciones.dropFoto' | translate }}"
                                         invalidFileExtension="{{
                   'organizaciones.extFotoIn' | translate
                                          }}"
                                         invalidMaxFileSize="{{
                      'organizaciones.invalidMax' | translate
                    }}"
                                         remove="{{ 'organizaciones.remFoto' | translate }}"
                                         select="{{ 'organizaciones.slFoto' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
              </div>

              <div class="col-lg-12 col-xl-2">

                <div *ngIf="!cabeceraBorrada && cabeceraPreview">
                  <button kendoButton
                          (click)="onClickEliminarImagen(content2)"
                          class="btn-sm btn-danger mr-1"
                          [disabled]="isNotInsC">
                    {{ "botones.eliminar" | translate }}
                  </button>
                </div>
              </div>

            </div>

            <ng-template #notImConf>
              <span><i>{{ "organizaciones.nocabecera" | translate }}</i></span>
            </ng-template>

            <div *ngIf="cabeceraPreview; else notImConf">
              <img [src]="cabeceraPreview" style="max-height: 113px" />
            </div>

            <kendo-label text="{{ 'organizaciones.activo' | translate }}">
            </kendo-label>

            <div class="caja">
              <kendo-switch [(ngModel)]="fv.activoOrganizacion"
                            [ngModelOptions]="{ standalone: true }"></kendo-switch>
            </div>

          </div>
        </div>
        <!--END DATOS-->
        <!-- CORREO-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.email" | translate }}</h3>
          </div>
          <div class="card-body">

            <div class="form-group">
              <kendo-label text="{{ 'organizaciones.email' | translate }}">
                <kendo-textbox formControlName="email"> </kendo-textbox>
              </kendo-label>
            </div>

            <div class="row">

              <div class="col-lg-8">
                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.smtp' | translate }}">
                    <kendo-textbox formControlName="smtp"> </kendo-textbox>
                  </kendo-label>
                </div>
              </div>
              <div class="col-lg-4">

                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.puerto' | translate }}">
                    <div class="caja">
                      <kendo-numerictextbox [value]="fv.puerto"
                                            formControlName="puerto"
                                            decimals="0"
                                            format="n">
                      </kendo-numerictextbox>
                    </div>
                  </kendo-label>
                </div>
              </div>

            </div>


            <div class="form-group">
              <kendo-label text="{{ 'configuracion.usuario' | translate }}">
              </kendo-label>
            </div>
            <div class="form-group">
              <kendo-textbox formControlName="usuario"
                             [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }">
              </kendo-textbox>
            </div>



            <div class="row">

              <div class="col-lg-4">
                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.cambiarcontraseña' | translate }}">
                  </kendo-label>

                  <div class="form-group">
                    <kendo-switch [(ngModel)]="fv.activoGuardarPassword"
                                  [ngModelOptions]="{ standalone: true }"
                                  name="guardarPassword"></kendo-switch>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">

                <div class="form-group">
                  <div class="form-group">
                    <kendo-label text="{{ 'configuracion.contrasena' | translate }}">
                    </kendo-label>
                  </div>

                  <input kendoTextBox
                         type="password"
                         formControlName="contrasena"
                         [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }" />
                </div>

              </div>

            </div>


          </div>
        </div>
        <!--END CORREO-->
        <!-- IDIOMAS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.idiomas" | translate }}</h3>
          </div>
          <div class="card-body">
            <kendo-buttongroup selection="multiple">

              <button *ngFor="let button of listaIdiomas"
                      kendoButton
                      [toggleable]="true"
                      (selectedChange)="selectedLangsChange($event, button)"
                      [selected]="button.selected">
                {{ button.nombre }}
              </button>
            </kendo-buttongroup>
          </div>

        </div>

        <!-- END IDOMAS-->

      </div>
      <div class="col-lg-4">
        <!-- INFORMACION-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "configuracion.informacion" | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="row form-group">
              <div class="col">
                <kendo-label text="{{ 'organizaciones.notificaciones' | translate }}"></kendo-label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="fv.activoNotificaciones" [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
              <div class="col">
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.faq' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="faqDisabled"
                                [(ngModel)]="fv.activoFAQ"
                                [ngModelOptions]="{ standalone: true }">
                  </kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.gesdocumental' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="gesDocDisabled"
                                [(ngModel)]="fv.activoGesDoc"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.contactos' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="contactosDisabled"
                                [(ngModel)]="fv.activoContactos"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- END INFORMACION-->
        <!-- AGENDA-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.agenda" | translate }}</h3>
          </div>
          <div class="card-body">

            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.agenda' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="agendaDisabled"
                                [(ngModel)]="fv.activoAgenda"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.agendaEdadmin' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-numerictextbox decimals="0"
                                        format="n"
                                        formControlName="agendaEdadMin"
                                        [value]="fv.agendaEdadMin">
                  </kendo-numerictextbox>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- END AGENDA-->
        <!-- MENSAJERIA-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.mensajeria" | translate }}</h3>
          </div>

          <div class="card-body">
            <div class="form-group row">

              <div class="col-sm-3">
                <kendo-label text="{{ 'organizaciones.mensajeria' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="mensajesDisabled"
                                [(ngModel)]="fv.activoMensajeria"
                                [ngModelOptions]="{ standalone: true }"
                                name="activoMensajeria"></kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.enviarIncidencias' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="fv.activoIncidenciasDepUnico"
                                [ngModelOptions]="{ standalone: true }"
                                name="activoIncidenciasDepUnico"></kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.departamento' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-dropdownlist [data]="departamentos"
                                      [value]="fv.departamento">
                  </kendo-dropdownlist>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- END MENSAJERIA-->
        <!-- RESERVAS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.reservas" | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.reservas' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [disabled]="reservasDisabled"
                                  [(ngModel)]="fv.activoReservas"
                                  [ngModelOptions]="{ standalone: true }"></kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.confirmarreservas' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="fv.activoConfirmReserva"
                                  [ngModelOptions]="{ standalone: true }">
                    </kendo-switch>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.mostrarusuario' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="fv.activoMostrarUsuarioCalendario"
                                  [ngModelOptions]="{ standalone: true }">
                    </kendo-switch>
                  </div>
                </div>
              </div>

              <div class="row form-group">
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.mindiaslab' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-numerictextbox decimals="0"
                                          format="n"
                                          [value]="fv.mindiasresrva"
                                          formControlName="mindiasresrva">
                    </kendo-numerictextbox>
                  </div>
                </div>
                <div class="col">
                  <kendo-label text="{{ 'organizaciones.aperturareservas' | translate }}">
                  </kendo-label>
                  <div class="caja">
                    <kendo-formfield>
                      <kendo-dropdownlist formControlName="apertura"
                                          [data]="aperturaRangos">
                      </kendo-dropdownlist>
                    </kendo-formfield>
                  </div>
                </div>
              </div>


              <div class="form-group">

                <kendo-label text="{{ 'organizaciones.correos' | translate }}">
                  <textarea kendoTextArea
                            style="resize: none"
                            rows="5"
                            formControlName="correosReservas"
                            [ngClass]="{
                        'is-invalid': submitted && f.correosReservas.errors
                      }">
                    </textarea>
                </kendo-label>

              </div>

            </div>
          </div>
        </div>
        <!-- END RESERVAS-->
        <!-- COORDENADAS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.coorenadas" | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="row form-group ">
              <div class="col">
                <kendo-label text="{{ 'organizaciones.latitud' | translate }}">
                  <kendo-textbox formControlName="latitud"> </kendo-textbox>
                </kendo-label>
              </div>

              <div class="col">
                <kendo-label text="{{ 'organizaciones.longitud' | translate }}">
                  <kendo-textbox formControlName="longitud"> </kendo-textbox>
                </kendo-label>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.zoom' | translate }}">
                  <kendo-numerictextbox [value]="fv.zoom" decimals="0" formControlName="zoom" format="n">
                  </kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>
        </div>
        <!-- END COORDENADAS-->
      </div>

      <div class="col-lg-4">
        <!-- FORO-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.foro" | translate }}</h3>
          </div>
          <div class="card-body">

            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.foro' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="foroDisabled"
                                [(ngModel)]="fv.activoForo"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.edadminparticipar' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-numerictextbox [value]="fv.edadforo"
                                        formControlName="edadforo"
                                        decimals="0"
                                        format="n">
                  </kendo-numerictextbox>
                </div>
              </div>
            </div>


          </div>
        </div>

        <!-- END FORO-->
        <!-- ENCUESTAS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.encuestas" | translate }}</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">

              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.encuestas' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [disabled]="encuetasDisabled"
                                [(ngModel)]="fv.activoEncuestas"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
              <div class="col">
                <kendo-label text="{{ 'organizaciones.edadminparticipar' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-numerictextbox [value]="fv.edadencuestasmin"
                                        formControlName="edadencuestasmin"
                                        decimals="0"
                                        format="n">
                  </kendo-numerictextbox>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- END ENCUENTAS-->
        <!-- RRSS-->
        <div class="card">
          <div class="card-header">
            <h3>{{ "organizaciones.rrss" | translate }}</h3>
          </div>
          <div class="card-body">

            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.facebook' | translate }}">
                </kendo-label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-switch [disabled]="facebookDisabled"
                              [(ngModel)]="fv.activoFacebook"
                              [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
              <div class="col">
                <kendo-textbox formControlName="fblink"
                               [ngClass]="{ 'is-invalid': submitted && f.fblink.errors }">
                </kendo-textbox>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.twitter' | translate }}">
                </kendo-label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-switch [disabled]="twitterDisabled"
                              [(ngModel)]="fv.activoTwitter"
                              [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
              <div class="col">
                <kendo-textbox formControlName="twlink"
                               [ngClass]="{ 'is-invalid': submitted && f.twlink.errors }">
                </kendo-textbox>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.flicker' | translate }}">
                </kendo-label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-switch [disabled]="flickrDisabled"
                              [(ngModel)]="fv.activoFlicker"
                              [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
              <div class="col">
                <kendo-textbox formControlName="fllink"
                               [ngClass]="{ 'is-invalid': submitted && f.fllink.errors }">
                </kendo-textbox>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.instagram' | translate }}">
                </kendo-label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-switch [disabled]="instagramDisabled"
                              [(ngModel)]="fv.activoInstagram"
                              [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
              <div class="col">
                <kendo-textbox formControlName="iglink"> </kendo-textbox>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-label text="{{ 'organizaciones.youtube' | translate }}">
                </kendo-label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-2">
                <kendo-switch [disabled]="youtubeDisabled"
                              [(ngModel)]="fv.activoYoutube"
                              [ngModelOptions]="{ standalone: true }"></kendo-switch>
              </div>
              <div class="col">
                <kendo-textbox formControlName="ytlink"
                               [ngClass]="{ 'is-invalid': submitted && f.ytlink.errors }">
                </kendo-textbox>
              </div>
            </div>
            <div class=" form-group">
              <kendo-label text="{{ 'organizaciones.multimedia' | translate }}">
              </kendo-label>

              <textarea kendoTextArea
                        style="resize: none"
                        rows="5"
                        formControlName="media"
                        [ngClass]="{ 'is-invalid': submitted && f.media.errors }">
                </textarea>
            </div>
            <div class="clearfix">
              <label>* {{ "organizaciones.separar" | translate }}</label>
              <label>* {{ "organizaciones.mediaapp" | translate }}</label>
            </div>

          </div>
        </div>

        <!-- END RRSS-->

      </div>
    </div>
    <!-- Relleno Idiomas-->
    <div class="card">
      <div class="card-body">
        <div class="clearfix">
          <kendo-label text="{{ 'organizaciones.idiomas' | translate }}">
          </kendo-label>

          <kendo-dropdownlist #dropdown
                              [data]="selectedLangs"
                              [defaultItem]="{ text: 'Select gender', value: null }"
                              [textField]="'nombre'"
                              [valueField]="'codigo'"
                              (valueChange)="valueIdiomaCamposChange($event, dropdown)">
          </kendo-dropdownlist>
        </div>

        <!--Campos Idiomas generacion basada en seleccin de diomnas del buttongroup-->
        <div id="camposIdiomas" *ngFor="let container of containersCampos">
          <div *ngIf="camposSelectedLang === container" id="{{ container }}">


            <kendo-label text="{{ 'organizaciones.descripcion' | translate }}">
              <textarea placeholder="{{ 'organizaciones.descripcionPH' | translate }}"
                        id="descripcion{{ container }}"
                        [(ngModel)]="camposValoresDescripcion[container]"
                        [ngModelOptions]="{ standalone: true }"
                        kendoTextArea
                        style="resize: none"
                        rows="5">
                </textarea>
            </kendo-label>


            <div class="clearfix">
              <div class="card-body">
                <h3>{{ "organizaciones.colaboradores" | translate }}</h3>

                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.nombre' | translate }}">
                    <kendo-textbox [disabled]="colaboradoresDisabled"
                                   placeholder="{{
                      'organizaciones.colaboradoresPH' | translate
                    }}"
                                   id="colaboradores{{ container }}"
                                   [(ngModel)]="camposValoresColaboradores[container]"
                                   [ngModelOptions]="{ standalone: true }">
                    </kendo-textbox>
                  </kendo-label>
                </div>
              </div>
            </div>

            <div class="clearfix">
              <div class="card-body">
                <h3>{{ "organizaciones.faq" | translate }}</h3>

                <div class="form-group">
                  <kendo-label text="{{ 'organizaciones.faq' | translate }}">
                    <textarea placeholder="{{ 'organizaciones.faq' | translate }}"
                              id="faq{{ container }}"
                              [(ngModel)]="camposValoresFAQ[container]"
                              [ngModelOptions]="{ standalone: true }"
                              kendoTextArea
                              style="resize: none"
                              rows="5">
                </textarea>
                  </kendo-label>
                </div>
              </div>
            </div>


          </div>
        </div>
        <!-- End Div campos dinámicos-->
      </div>
    </div>
    <!-- End relleno idomas-->
  </form>
 
  <div class="">
    <button
      kendoButton
      [disabled]="loading"
      (click)="onSubmit()"
      class="btn mr-1 btn-primary"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      {{ "botones.guardar" | translate }}
    </button>
    <a routerLink="/organizaciones" class="btn mr-1 btn-danger">{{
      "botones.cancelar" | translate
    }}</a>
  </div>

  <!--TODO: Juntar los dos popups pasando un paramtero a ng-template-->
  <!--POPUP ELIMINAR AVATAR -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "enlaces.preguntaeliminarimagen" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ "botones.no" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="eliminarImagen('avatar')"
      >
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>
  <!--POPUP ELIMINAR CABECERA -->
  <ng-template #content2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "enlaces.preguntaeliminarimagen" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ "botones.no" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="eliminarImagen('cabecera')"
      >
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>
      <!--POPUP GESTION ADMINS ENTE-->
      <div class="example-wrapper">
        <kendo-dialog
          title=""
          [maxWidth]="1000"
          [maxHeight]="800"
          (close)="cerrarPopUpAdmins()"
          *ngIf="showAdmins"
        >
          <h3>{{ "agentes.gestionadmins" | translate }}</h3>
          <div>
            <kendo-grid
              [kendoGridBinding]="usuariosAdminsLista"
              kendoGridSelectBy="id"
              filterable="menu"
              [navigable]="true"
              [sortable]="true"
              scrollable="virtual"
              [rowHeight]="36"
              [height]="500"
              [resizable]="true"
              [selectable]="{
                cell: false,
                checkboxOnly: false,
                drag: true,
                enabled: true,
                mode: 'multiple'
              }"
            >
              <!--checkbox-->
              <kendo-grid-checkbox-column width="7%">
                <ng-template kendoGridHeaderTemplate>
                  <input
                    class="k-checkbox"
                    id="selectAllCheckboxId"
                    kendoGridSelectAllCheckbox
                    [state]="selectAllState"
                    (selectAllChange)="onSelectAllChange($event)"
                  />
                  <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
              </kendo-grid-checkbox-column>
    
              <kendo-grid-column
                field="nombreUsuario"
                title="{{ 'organizaciones.nombre' | translate }}"
                width="10%"
              ></kendo-grid-column>
              <kendo-grid-column
                field="email"
                title="{{ 'agentes.email' | translate }}"
                width="10%"
              ></kendo-grid-column>
              <kendo-grid-column
                title="{{ 'agentes.asignado' | translate }}"
                width="20%"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input
                    type="checkbox"
                    [checked]="dataItem.isMine"
                    id="comentarC{{ dataItem.id }}"
                    [(ngModel)]="dataItem.isMine"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
    
          <kendo-dialog-actions>
            <button type="button" class="btn btn-danger" (click)="cerrarPopUpAdmins()">
              {{ "cancelar" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-success"
              (click)="btnActualizarAdmins()"
            >
              {{ "agentes.guardar" | translate }}
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>
      </div>
    
      <!--END POPUP ADMINS ENTE-->
</div>
<ng-template #dataLoading><h3>Loading...</h3></ng-template>
