import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';

import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ContactosComponent } from './contactos/contactos.component';
import { ContactosDetalleComponent } from './contactos/contactos-detalle/contactos-detalle.component';
import { TransportesComponent } from './transportes/transportes.component';
import { TransportesDetalleComponent } from './transportes/transportes-detalle/transportes-detalle.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { EnlacesDetalleComponent } from './enlaces/enlaces-detalle/enlaces-detalle.component';
import { RecorridosComponent } from './recorridos/recorridos.component';
import { RecorridosDetalleComponent } from './recorridos/recorridos-detalle/recorridos-detalle.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { DepartamentosDetalleComponent } from './departamentos/departamentos-detalle/departamentos-detalle.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { PruebaComponent } from './prueba/prueba.component';
import { PatrocinadoresComponent } from './patrocinadores/patrocinadores.component';
import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrganizacionesDetalleComponent } from './organizaciones/organizaciones-detalle/organizaciones-detalle.component';
import { ConfiguracionInicialComponent } from './configuracion-inicial/configuracion-inicial.component';
import { AgentesComponent } from './agentes/agentes.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { AgentesdetalleComponent } from './agentes/agentesdetalle/agentesdetalle.component';
import { ColaboradoresdetalleComponent } from './colaboradores/colaboradoresdetalle/colaboradoresdetalle.component';
import { AgentesTiposComponent } from './agentes-tipos/agentes-tipos.component';
import { AgentesTiposDetallesComponent } from './agentes-tipos/agentes-tipos-detalles/agentes-tipos-detalles.component';
import { DepartamentosEnteComponent } from './departamentos-ente/departamentos-ente.component';
import { DepartamentosEnteDetallesComponent } from './departamentos-ente/departamentos-ente-detalles/departamentos-ente-detalles.component';
import { ContactosEnteComponent } from './contactos-ente/contactos-ente.component';
import { ContactosEnteDetallesComponent } from './contactos-ente/contactos-ente-detalles/contactos-ente-detalles.component';

const loginModule = () =>
  import('./login/login.module').then((x) => x.LoginModule);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    loadChildren: loginModule,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: loginModule },
  {
    path: 'profile',
    component: DetailsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'update', component: UpdateComponent, canActivate: [AuthGuard] },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'contactos',
    component: ContactosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: ContactosDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: ContactosDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'transportes',
    component: TransportesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: TransportesDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: TransportesDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'enlaces',
    component: EnlacesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: EnlacesDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EnlacesDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'recorridos',
    component: RecorridosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: RecorridosDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: RecorridosDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'departamentos',
    component: DepartamentosComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: DepartamentosDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: DepartamentosDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'configuracion',
    component: ConfiguracionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios-tipos',
    component: UsuariosTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'patrocinadores',
    component: PatrocinadoresComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: PatrocinadoresComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: PatrocinadoresComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'organizaciones',
    component: OrganizacionesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: OrganizacionesDetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: OrganizacionesDetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes',
    component: AgentesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: AgentesdetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: AgentesdetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'agentes-tipos',
    component: AgentesTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: AgentesTiposDetallesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: AgentesTiposDetallesComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'colaboradores',
    component: ColaboradoresComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: ColaboradoresdetalleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: ColaboradoresdetalleComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'contactos-ente/:id',
    component: ContactosEnteComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'editar/:id',
        component: ContactosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: ContactosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'departamentos-ente/:id',
    component: DepartamentosEnteComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [ 
      {
        path: 'editar/:idDep',
        component: DepartamentosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: DepartamentosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'gestionar/:id/:idDep',
        component: DepartamentosEnteDetallesComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'configuracion-inicial',
    component: ConfiguracionInicialComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  //Pruebas
  {
    path: 'pruebas',
    component: PruebaComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: PruebaComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: PruebaComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  //End Pruebas

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
