import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/contactosente`;


@Injectable()
export class ContactosenteService {

  constructor(private http: HttpClient) { }

  public GetById(id: String): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  insert(data) {
    return this.http.post<any>(baseUrl + "/crear", {id: -1, contacto: data.nombre1, email: data.email, phone: data.telefono}, { withCredentials: true });
  }

  update(data) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/editar", { id: data.codigo, contacto: data.nombre1, email: data.email, phone: data.telefono}, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    //console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append("codigos", data.codigos);
    //console.log(JSON.stringify(formData));

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

}