import { Component, OnInit } from '@angular/core';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-departamentos-ente',
  templateUrl: './departamentos-ente.component.html',
})
export class DepartamentosEnteComponent implements OnInit {

  public dataDepartamentos: [];
  lang;
  public seleccionados: number[] = [];
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  idOrg:string;
  constructor(
    private departamentosEnteService: DepartamentosEnteService,
    private route: ActivatedRoute,
    private menuService: MenuService,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('agentes.departamentos');
    this.departamentosEnteService.GetById(this.route.snapshot.params['id']).subscribe((result) => {
      this.dataDepartamentos = result.data;
    })
  }

  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['id'];
    this.lang = this.translateService.getDefaultLang();
    this.departamentosEnteService.GetById(this.idOrg).subscribe((result) => {
      this.dataDepartamentos = result.data;
      console.log('dataDepartamentos Loaded :'+this.dataDepartamentos.length.toString())
    });
  }

  onClickNuevo() {
    //console.log('departamentos-ente/'+this.idOrg+'/crear/');
    this.router.navigate(['departamentos-ente/'+this.idOrg+'/crear/',{ idorg:this.idOrg }]);
  }

  onClickEditar() {
    console.log('EDITAR PULSADO');
    console.log(this.seleccionados[0]);
    if (this.seleccionados.length>0) {
      this.router.navigate(['departamentos-ente/'+this.idOrg+'/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['departamentos-ente/'+this.idOrg+'/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.departamentosEnteService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.departamentosEnteService.GetById(this.idOrg).subscribe((result) => {
            this.dataDepartamentos = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }


}
