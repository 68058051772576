import { Component, OnInit } from '@angular/core';
import { Contacto } from '@app/_models';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router"

import { ContactosService } from '@app/_services/contactos.service';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { ContactosenteService } from "@app/_services/contactosente.service";

@Component({
  selector: 'app-contactos-ente',
  templateUrl: './contactos-ente.component.html'
})
export class ContactosEnteComponent implements OnInit {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public dataContactos: [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  lang;
  idOrg:string;
  constructor(private contactosService: ContactosenteService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('contactos.contactos').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/contactos-ente') {
          this.menuService.titulo = this.translateService.instant('contactos.contactos');
          this.contactosService.GetById(this.idOrg).subscribe((result) => {
            this.dataContactos = result.data;
            }
          );
        } else {
         
        }
      }
    });

    this.idOrg = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.contactosService.GetById(this.idOrg).subscribe((result) => {
      this.dataContactos = result.data;
      console.log('CONTACTOS QUE LLEGAN: ');
      console.log(result.data);
      }
    );
    this.lang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = this.translateService.currentLang;
      this.menuService.titulo = this.translateService.instant('contactos.contactos');
    });
  }

  onClickNuevo() {
    this.router.navigate(['contactos-ente/'+this.idOrg+'/crear/',{ idorg:this.idOrg }]);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['contactos-ente/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['contactos-ente/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.contactosService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.contactosService.GetAll().subscribe((result) => {
            this.dataContactos = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
